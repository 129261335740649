import React, {useEffect, useState} from 'react';
import './App.css';
// import LoggedIn from "./components/LoggedIn"
// import {defaultState, reducer} from "./stateAndReducer/stateAndReducer"
import {config} from "./config/config"
import {SelectInput} from "./common/select-input.component";
import {Alert, Button, Col, Row, Table} from "react-bootstrap";
import {AlertModel} from "./types/Alert";
import * as zlib from "zlib";
import {requestMap} from "./common/request";
import {
    getLocalStorageRedirectUrl,
    handleAuthenticatingThroughSingleSignOn,
    isAuthenticated,
    isAuthenticatingThroughSingleSignOn,
    isBearerTokenExpired,
    removeAuthenticationReference
} from "./common/authentication.helper";

export const MyContext = React.createContext(null);
export const url = config.url

type ClientInfo = {
    client_name: string
    account_id: string
    redirect_uri: string
    type: string
}

const Types = {
    IMPLICIT_OID: "Implicit OID",
    UNSOLICITED_SAML: "Unsolicited SAML",
    SAML_REQUEST: "SAML Request",
    OID_AUTHORIZATION_CODE: "OID Authorization Code",
}

const nimbusClients: Record<string, ClientInfo> = {
    "3abdb1bd-e748-fff7-029c-042646abdbbc": {
        client_name: "OID Implicit",
        account_id: "",
        redirect_uri: process.env.REACT_APP_REDIRECT as string,
        type: Types.IMPLICIT_OID
    },
    "06bdb1c0-3562-2c90-ac65-8b6fec0cf032": {
        client_name: "SAMLUnsolicited",
        account_id: "505592513312",
        redirect_uri: process.env.REACT_APP_REDIRECT as string,
        type: Types.UNSOLICITED_SAML
    },
    "40bdb1bf-2deb-1c64-351d-5663a00d69a0": {
        client_name: "SAML Request",
        account_id: "",
        redirect_uri: process.env.REACT_APP_REDIRECT as string,
        type: Types.SAML_REQUEST
    },
    "88bdb1b7-a2ad-1e0c-892a-f4e6d924d50f": {
        client_name: "OID Authorization",
        account_id: "",
        redirect_uri: process.env.REACT_APP_REDIRECT as string,
        type: Types.OID_AUTHORIZATION_CODE
    },
}

const patronusClients: Record<string, ClientInfo> = {
    "1ebdb1d7-07e1-fc77-9f99-955f5618c665": {
        client_name: "OID Implicit Prod",
        account_id: "",
        redirect_uri: process.env.REACT_APP_REDIRECT as string,
        type: Types.IMPLICIT_OID
    },
    "9ebdb1d8-a99e-b2bf-b71c-578b9871cf1d": {
        client_name: "SAMLUnsolicitedProd",
        account_id: "505592513312",
        redirect_uri: process.env.REACT_APP_REDIRECT as string,
        type: Types.UNSOLICITED_SAML
    },
    "72bdb1d7-c787-0f1d-dcca-be22c6ff910f": {
        client_name: "SAML Request Prod",
        account_id: "",
        redirect_uri: process.env.REACT_APP_REDIRECT as string,
        type: Types.SAML_REQUEST
    },
    "22bdb1d4-26ae-625a-7daa-70bff066f44a": {
        client_name: "OID Authorization Prod",
        account_id: "",
        redirect_uri: process.env.REACT_APP_REDIRECT as string,
        type: Types.OID_AUTHORIZATION_CODE
    },
}

export interface Props {
    isUserAuthenticated: boolean;

    authenticatedUser(): { type: string; }
}

export const App: React.FC = () => {
    // const [state, dispatch] = React.useReducer(reducer, defaultState)
    const [env, setEnv] = useState("nimbus")
    const [selectedConfig, setSelectedConfig] = useState<Record<string, ClientInfo>>(nimbusClients)
    const [notice, setNotice] = useState(AlertModel)

    let makeString = () => {
        let outString: string = '';
        let inOptions: string = 'abcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 32; i++) {
            outString += inOptions.charAt(Math.floor(Math.random() * inOptions.length));
        }
        return outString;
    }

    //let gotToSingleSignOn = () => {
    let gotToSingleSignOn = React.useCallback(async () => {

        let stateValue = makeString()
        localStorage.setItem("stateValue", stateValue);
        let nonceValue = makeString()
        localStorage.setItem("nonceValue", nonceValue);

        window.location.href = `https://sso.federation.nnip.net/auth/oid/login?client_id=a2be5bec-7cff-131e-5a57-6984ad0248fa&redirect_uri=${process.env.REACT_APP_REDIRECT as string}&state=${stateValue}&nonce=${nonceValue}`;
    }, [])

    let goToHomePage = () => {
        window.location.href = '/';
    }

    let goToPage = (url: string) => {
        window.location.href = url;
    }

    //let initialize = () => {
    let initialize = React.useCallback(async () => {
        if (isAuthenticatingThroughSingleSignOn()) {
            handleAuthenticatingThroughSingleSignOn();

            if (getLocalStorageRedirectUrl() !== "") {
                const url = getLocalStorageRedirectUrl()
                return goToPage(url);
            }

            return goToHomePage();
        }

        if (!isAuthenticated()) {
            return gotToSingleSignOn();
        }

        if (isAuthenticated() && isBearerTokenExpired()) {
            removeAuthenticationReference();
            return gotToSingleSignOn();
        }

        // authenticatedUser()
    }, [gotToSingleSignOn])


    useEffect(() => {
        if (!isAuthenticated()) {
            initialize()
        }
        // Check for OID flow if an access token has been succesfully returned
        if (window.location.href.split("access_token=").length === 2) {
            setNotice(prevState => {
                return {...prevState, show: true, mssg: `${prevState.mssg} @ ${getTime()}`}
            })
            // For OID Authorization flow, do a consecutive call for the access token and check if it's received in the body
        } else if (window.location.href.split("code=").length === 2) {
            let code = window.location.href.split("code=")[1].split("&")[0]
            let clientId = env === "nimbus" ? "88bdb1b7-a2ad-1e0c-892a-f4e6d924d50f" : "22bdb1d4-26ae-625a-7daa-70bff066f44a"
            let clientSec = env === "nimbus" ? "bAh5U4k3JzgBRKSiEeFY9jeRtldg8oyhTqL" : "JHUr7xAZcaUvsTWeoSIyyNxovVrPQCLAPqd"
            let getTokenUrl = `https://sts.federation.nnip.${env === "nimbus" ? "net" : "com"}/token`
            let body: string = `grant_type=authorization_code&code=${code}&client_secret=${clientSec}&client_id=${clientId}&redirect_uri=${process.env.REACT_APP_REDIRECT as string}`
            requestMap.postRequest(getTokenUrl, {}, body).then(async response => {
                if (response["access_token"] != null) {
                    setNotice(prevState => {
                        return {...prevState, show: true, mssg: `${prevState.mssg} @ ${getTime()}`}
                    })
                }
            })
        }
    }, [env, initialize])

    // Handle the environment select drop down
    const handleSelectChange = async (controlId: string, selected?: { "value": string, "label": string } | { "value": string, "label": string }[] | null) => {
        // Set environment variable
        setEnv((selected as { "value": string, "label": string }).value);
        // Set the config for the environment
        if ((selected as { "value": string, "label": string }).value === "nimbus") {
            setSelectedConfig({...nimbusClients})
        } else {
            setSelectedConfig({...patronusClients})
        }
    };

    // HandleChange for the on select function of the user select
    const login = async (clientId: string) => {
        console.log("Logging in for: ", clientId)
        let loginUrl = ""
        switch (selectedConfig[clientId].type) {
            case Types.IMPLICIT_OID:
                loginUrl = `https://sso.federation.nnip.${env === "nimbus" ? "net" : "com"}/auth/oid/login?client_id=${clientId}&redirect_uri=${selectedConfig[clientId].redirect_uri}`
                break;
            case Types.UNSOLICITED_SAML:
                loginUrl = `https://sso.federation.nnip.${env === "nimbus" ? "net" : "com"}/auth/saml2/idp?initiatedrplogin=urn:aws:${selectedConfig[clientId].account_id}:aws:${selectedConfig[clientId].client_name}`
                window.open(loginUrl)
                return;
            case Types.SAML_REQUEST:
                loginUrl = `https://sso.federation.nnip.${env === "nimbus" ? "net" : "com"}/auth/saml2/login?SAMLRequest=${await samlRequest()}`
                window.open(loginUrl)
                return;
            case Types.OID_AUTHORIZATION_CODE:
                loginUrl = `https://sso.federation.nnip.${env === "nimbus" ? "net" : "com"}/auth/oid/getAuthCode?client_id=${clientId}&redirect_uri=${selectedConfig[clientId].redirect_uri}&response_type=code&scope=openid`
                break;
            default:
            // DO NOTHING
        }
        // let response = await requestMap.getRequest(loginUrl, {})
        // console.log(response)
        // window.open(loginUrl)
        window.location.replace(loginUrl)
    };

    return (
        <div>
            {
                !isAuthenticated() && <div>Loading</div>
            }
            {
                isAuthenticated() && (


                    <Col>
                        <div style={{textAlign: "right"}}>
                            {localStorage.getItem("email")}
                            <br/><br/>
                        </div>
                        <Row style={{display: "block"}}>
                            <SelectInput controlId={"env"} isSearchable={false} label={"Environment"}
                                         value={{label: env, value: env}}
                                         handleChange={(e: any) => handleSelectChange("env", e)}
                                         options={[
                                             {label: "nimbus", value: "nimbus"},
                                             {label: "patronus", value: "patronus"}
                                         ]}/>
                        </Row>
                        <Row>
                            <Table striped bordered hover variant="light">
                                <thead>
                                <tr>
                                    <th>Client Name</th>
                                    <th>Client ID</th>
                                    <th>Login</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    Object.keys(selectedConfig).map((clientId: string) => {
                                        return (
                                            <tr>
                                                <td>{selectedConfig[clientId].client_name}</td>
                                                <td>{clientId}</td>
                                                <td>
                                                    <Button variant="success"
                                                            onClick={() => login(clientId)}>Login</Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </Table>
                            {/*{state.token && <LoggedIn/>}*/}
                        </Row>

                        {notice.show &&
                        <Alert variant={notice.variant}
                            onClose={() => setNotice({...notice, show: false})}
                               dismissible>
                            {notice.mssg}
                        </Alert>
                        }
                    </Col>

                )
            }
        </div>

    );
};

export default App;

// function OIDFlow() {
//     if (document.getElementById("OIDTextBox")!.nodeValue) {
//         window.location.replace(document.getElementById("OIDTextBox")!.nodeValue!)
//     } else {
//         window.location.replace(document.getElementById("OIDTextBox")!.getAttribute("placeholder")!)
//     }
// }
//
// function SAMLFlow() {
//     console.log("this is window location ", window.location)
//     if (document.getElementById("SAMLTextBox")!.nodeValue) {
//         window.location.replace(document.getElementById("SAMLTextBox")!.nodeValue!)
//     } else {
//         window.location.replace(document.getElementById("SAMLTextBox")!.getAttribute("placeholder")!)
//     }
// }

const samlRequest = () => {
    let responseDeflateString = `<samlp:AuthnRequest xmlns:samlp="urn:oasis:names:tc:SAML:2.0:protocol" AssertionConsumerServiceURL="${process.env.REACT_APP_REDIRECT as string}" Destination="${process.env.REACT_APP_REDIRECT as string}" ID="_ad95a125-2e29-46aa-af70-f676a11cc5fd" IssueInstant="${getTime()}" Version="2.0"><saml:Issuer xmlns:saml="urn:oasis:names:tc:SAML:2.0:assertion" Format="urn:oasis:names:tc:SAML:2.0:nameid-format:entity">Patronus E2E</saml:Issuer></samlp:AuthnRequest>`

    let buffer = zlib.deflateRawSync(responseDeflateString);
    return encodeURIComponent(buffer.toString('base64'))
}

const getTime = () => {
    let currentDate = new Date();
    return currentDate.getFullYear() + "-"
        + (currentDate.getMonth() + 1) + "-"
        + currentDate.getDate() + "T"
        + currentDate.getHours() + ":"
        + currentDate.getMinutes() + ":"
        + currentDate.getSeconds() + "."
        + currentDate.getMilliseconds() + "Z"
}