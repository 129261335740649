export interface Alert {
    [key: string]: any

    variant: "success" | "danger",
    mssg: string,
    show: boolean,
}

export const AlertModel: Alert = {
    variant: "success",
    mssg: 'Successful Login!',
    show: false,
};