export function Request(method: string,
                        url: string,
                        body: any = null,
                        options = {}): Promise<any> {
    return fetch(url, {
        method,
        ..._hasBody(body),
        ...options,
    })
        .then(async response => {
            return _getParsedResponse(await response.text());
        })
        .catch((errorResponse) => {
            console.error(errorResponse)
        })
}

function _hasBody(requestBody: any): {} | null {
    const isString = (tbd: any): tbd is string => {return true}
    if (requestBody && isString(requestBody)) {
        return {body: requestBody}

    } else if (requestBody) {
        return {
            body: JSON.stringify(requestBody)
        };
    }
    return null
}

function _getParsedResponse(response: string): {} {
    try {
        return response ? JSON.parse(response) : {}
    } catch (e) {
        return {}
    }
}

export const requestMap = {
    /*
Generic GET request for the clients API that takes the following parameters:
    path: string = the path of the url
    queryStringParameters: Record<string, string> = all the query parameters that have to be added to the url
 */
    postRequest(url: string, queryStringParameters: Record<string, string>, body: any): Promise<any> {
        // Creating the query string parameters for the url
        let queryString = "";
        for (let key in queryStringParameters) {
            if (queryString !== "") {
                queryString += "&"
            }
            queryString += key + "=" + queryStringParameters[key]
        }

        return Request("POST", url, body, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
                // "Content-Length": body.length
            }
        })
    },
}