import React from 'react'
import {Col, Form, Row} from 'react-bootstrap'
import Select from 'react-select'

export interface SelectInputProps {
    controlId: string
    isSearchable: boolean
    placeholder?: string
    label: string
    value: { value: string, label: string }
    options: { value: string, label: string }[]
    handleChange: any
    hidden?: boolean
    disabled?: boolean
    style?: any
}

export const SelectInput: React.FC<SelectInputProps> = (props: SelectInputProps) => {
    return (
        <Form.Group style={props.style} hidden={props.hidden} as={Row} controlId={props.controlId}>
            <Form.Label column sm={2}>{props.label}</Form.Label>
            <Col sm={10}>
                <Select isDisabled={props.disabled} placeholder={props.placeholder}
                        value={props.value} isSearchable={props.isSearchable}
                        options={props.options} onChange={props.handleChange}/>
            </Col>
        </Form.Group>
    )
};
