export const BEARER = 'bearer';
export const EMAIL = 'email';
export const REDIRECTURL = 'redirectUrl';

export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';

const queryString = require('query-string');

export function getBearerFromHash(): any {
    const {access_token: bearer} = queryString.parse(window.location.hash);
    return bearer;
}

export function getIdTokenBearerFromHash(): any {
    const {id_token: bearer} = queryString.parse(window.location.hash);
    return bearer;
}

export function getStateFromHash(): any {
    const {state} = queryString.parse(window.location.hash);
    return state;
}

export function handleAuthenticatingThroughSingleSignOn(): void {
    if((getStateFromHash() === localStorage.getItem("stateValue"))
            && (_getParsedJsonWebToken(getIdTokenBearerFromHash()).nonce === localStorage.getItem("nonceValue"))
            && (_getParsedJsonWebToken(getBearerFromHash()).aud === "a2be5bec-7cff-131e-5a57-6984ad0248fa")
    ) {
        localStorage.setItem(BEARER, getBearerFromHash());
        localStorage.setItem(EMAIL, _getParsedJsonWebToken(getBearerFromHash()).sub);
    }
}

export function isAuthenticated() {
    return localStorage.getItem(BEARER);
}

export function isBearerTokenExpired() {
    const jwt = _getParsedJsonWebToken(localStorage.getItem(BEARER));
    const {exp} = jwt;

    return _isExpired(exp);
}

export function getUserTeams() {
    const jwt = _getParsedJsonWebToken(localStorage.getItem(BEARER));
    return (jwt as {"userInfo": {"teams": Record<string, string>}}).userInfo.teams;
}

export function getUserRoles() {
    const jwt = _getParsedJsonWebToken(localStorage.getItem(BEARER));
    var roles = (jwt as {"userInfo": {"authorization": {"roles": string[]}}}).userInfo.authorization.roles;
    if (roles == null) {
        return []
    }
    return roles
}

export function isAuthenticatingThroughSingleSignOn(): boolean {
    return !!getBearerFromHash();
}

export function removeAuthenticationReference(): void {
    localStorage.removeItem(BEARER);
    localStorage.removeItem(EMAIL);
}

export function _isExpired(timestamp: number): boolean {
    return timestamp < Math.floor(new Date().getTime() / 1000);
}

export function _getParsedJsonWebToken(token: any) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload: string = decodeURIComponent(
        atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
}

export function getLocalStorageRedirectUrl(): string {
    const url = localStorage.getItem(REDIRECTURL)
    if (url !== null) {
        return url
    } else {
        return ""
    }
}

export function authenticateUser() {
    return ({type: AUTHENTICATE_USER});
}
